import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import useMe from '@/hooks/useMe';
import { SettingsFormValues, settingsSchema } from '@/schema/SettingsFormValues';
import { IUser } from '@/types';
import axiosClient from '@/utils/axiosClient';

// Year bounds (example)
const MIN_YEAR = 1900;
const MAX_YEAR = new Date().getFullYear();

// Helper: map user to initial form values
function mapFormValues(user: IUser) {
  let values = {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    phone: user.phone || '',
    month: '',
    day: '',
    year: '',
  };

  // If user.dob is in YYYY-MM-DD format, split it
  if (user.dob && user.dob.includes('-')) {
    const [y, m, d] = user.dob.split('-');
    values = {
      ...values,
      month: m,
      day: d,
      year: y,
    };
  }

  return values;
}

// Formatting helpers
const formatTwoDigits = (numStr: string) => {
  const num = parseInt(numStr, 10);
  return num < 10 ? `0${num}` : `${num}`;
};

const isValidMonth = (value: string) => {
  const num = parseInt(value, 10);
  return num >= 1 && num <= 12;
};

const isValidDay = (value: string) => {
  const num = parseInt(value, 10);
  return num >= 1 && num <= 31;
};

const isValidYear = (value: number) => {
  return value >= MIN_YEAR && value <= MAX_YEAR;
};

const Settings: React.FC = () => {
  const currentUser = useMe();
  const { toast } = useToast();

  const {
    reset,
    register,
    setValue,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsFormValues>({
    resolver: zodResolver(settingsSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      phone: '',
      month: '',
      day: '',
      year: '',
    },
  });

  // When user data arrives, reset the form
  useEffect(() => {
    if (currentUser?.data) {
      const formValues = mapFormValues(currentUser.data);
      reset(formValues);
    }
  }, [currentUser?.data]);

  const prevYear = useRef<string>('');

  // Watch month, day, year changes
  const watchMonth = watch('month');
  const watchDay = watch('day');
  const watchYear = watch('year');

  // ------------------------------------------
  // Auto-format & jump: MONTH
  // ------------------------------------------
  useEffect(() => {
    if (!watchMonth) return;

    const val = watchMonth.replace(/\D/, ''); // keep digits only
    if (!val) {
      setValue('month', '');
      return;
    }

    if (val.length === 1 && parseInt(val, 10) > 1) {
      // user typed a single digit, e.g. '7'
      const twoDigits = formatTwoDigits(val); // '07'
      if (isValidMonth(twoDigits)) {
        // set the final month, then jump to day
        setValue('month', twoDigits);
        document.getElementById('dayInput')?.focus();
      } else {
        // invalid single digit
        setValue('month', '');
      }
    } else if (val.length >= 2) {
      const candidate = val.slice(0, 2);
      if (isValidMonth(candidate)) {
        setValue('month', candidate);
        document.getElementById('dayInput')?.focus();
      } else {
        setValue('month', '');
      }
    }
    // else, if val is length 0, we setValue('month',''), done above
  }, [watchMonth, setValue]);

  // ------------------------------------------
  // Auto-format & jump: DAY
  // ------------------------------------------
  useEffect(() => {
    if (!watchDay) return;

    const val = watchDay.replace(/\D/, '');
    if (!val) {
      setValue('day', '');
      return;
    }

    if (val.length === 1 && parseInt(val, 10) > 3) {
      // single digit day, e.g. '7'
      const twoDigits = formatTwoDigits(val); // '07'
      if (isValidDay(twoDigits)) {
        setValue('day', twoDigits);
        document.getElementById('yearInput')?.focus();
      } else {
        setValue('day', '');
      }
    } else if (val.length >= 2) {
      const candidate = val.slice(0, 2);
      if (isValidDay(candidate)) {
        setValue('day', candidate);
        document.getElementById('yearInput')?.focus();
      } else {
        setValue('day', '');
      }
    }
  }, [watchDay, setValue]);

  // ------------------------------------------
  // Auto-format & jump: YEAR
  // ------------------------------------------
  useEffect(() => {
    if (!watchYear) return;

    const val = watchYear.replace(/\D/g, ''); // remove non-digits
    if (!val) {
      setValue('year', '');
      prevYear.current = '';
      return;
    }

    if (prevYear.current.length < val.length) {
      // User is adding digits
      if (val.length === 2 && val !== '19') {
        const num = parseInt(val, 10);
        const guess = num < 30 ? 2000 + num : 1900 + num;
        if (isValidYear(guess)) {
          setValue('year', guess.toString());
        }
      } else if (val.length === 4) {
        const num = parseInt(val, 10);
        if (isValidYear(num)) {
          setValue('year', val);
        }
      }
    }

    // Update previous value
    prevYear.current = val.slice(0, 4);
  }, [watchYear, setValue]);

  // ------------------------------------------
  // Backspace to jump backward
  // ------------------------------------------
  const handleBackspace = (e: KeyboardEvent<HTMLInputElement>, field: 'month' | 'day' | 'year') => {
    if (e.key === 'Backspace') {
      const values = getValues();
      // If current field is empty, jump back
      if (!values[field]) {
        e.preventDefault();
        if (field === 'day') {
          document.getElementById('monthInput')?.focus();
        } else if (field === 'year') {
          document.getElementById('dayInput')?.focus();
        }
      }
    }
  };

  // ------------------------------------------
  // Submit
  // ------------------------------------------
  const onSubmit = async (data: SettingsFormValues) => {
    try {
      const date_of_birth = `${data.year}-${data.month}-${data.day}`;

      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        date_of_birth,
      };
      // Update user data
      await axiosClient.put(`/v1/users/${currentUser?.data?.id}`, payload);
      currentUser.refetch?.();

      toast({
        title: 'Success',
        description: 'Your settings have been updated.',
      });
    } catch (error) {
      console.error('Failed to update user data', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to update your settings. Please try again.',
      });
    }
  };

  // toast({
  //   title: 'Success',
  //   description: 'Your settings have been updated.',
  // });

  // ------------------------------------------
  // Render
  // ------------------------------------------
  return (
    <>
      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Settings</div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            Update your personal information
          </div>
        </div>
      </div>
      <Card className="mb-4">
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 mt-4">
            <div>
              <Label htmlFor="first_name">First Name</Label>
              <Input id="first_name" placeholder="Enter your first name" {...register('first_name')} />
              {errors.first_name && <p className="mt-1 text-sm text-red-500">{errors.first_name.message}</p>}
            </div>

            <div>
              <Label htmlFor="last_name">Last Name</Label>
              <Input id="last_name" placeholder="Enter your last name" {...register('last_name')} />
              {errors.last_name && <p className="mt-1 text-sm text-red-500">{errors.last_name.message}</p>}
            </div>

            <div>
              <Label htmlFor="phone">Phone Number (SMS Notifications will be sent)</Label>
              <InputMask
                {...register('phone')}
                mask="(999) 999-9999"
                onChange={(e) => {
                  setValue('phone', e.target.value);
                }}
                id="phone"
                placeholder="Enter your mobile number"
              >
                {(inputProps: any) => <Input type="tel" {...inputProps} />}
              </InputMask>
              {errors.phone && <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>}
            </div>

            <div>
              <Label>Date of Birth</Label>
              <div className="flex items-center gap-2">
                {/* Month */}
                <Input
                  id="monthInput"
                  placeholder="MM"
                  maxLength={2}
                  className="text-center w-14"
                  {...register('month')}
                  onKeyDown={(e) => handleBackspace(e, 'month')}
                />
                <span>/</span>
                {/* Day */}
                <Input
                  id="dayInput"
                  placeholder="DD"
                  maxLength={2}
                  className="text-center w-14"
                  {...register('day')}
                  onKeyDown={(e) => handleBackspace(e, 'day')}
                />
                <span>/</span>
                {/* Year */}
                <Input
                  id="yearInput"
                  placeholder="YYYY"
                  maxLength={4}
                  className="w-20 text-center"
                  {...register('year')}
                  onKeyDown={(e) => handleBackspace(e, 'year')}
                />
              </div>
              {errors.day?.message === 'Invalid date of birth.' && (
                <p className="mt-1 text-sm text-red-500">{errors.day.message}</p>
              )}
              {errors.month && <p className="mt-1 text-sm text-red-500">{errors.month.message}</p>}
              {errors.day && errors.day.message !== 'Invalid date of birth.' && (
                <p className="mt-1 text-sm text-red-500">{errors.day.message}</p>
              )}
              {errors.year && <p className="mt-1 text-sm text-red-500">{errors.year.message}</p>}
            </div>

            <Button type="submit" className="mt-2">
              Save Changes
            </Button>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default Settings;
