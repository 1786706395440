import { z } from 'zod';

const MIN_YEAR = 1900;
const MAX_YEAR = new Date().getFullYear();

export const settingsSchema = z
  .object({
    first_name: z
      .string()
      .min(1, { message: 'First name is required' })
      .max(50, { message: 'First name must be at most 50 characters' }),
    last_name: z
      .string()
      .min(1, { message: 'Last name is required' })
      .max(50, { message: 'Last name must be at most 50 characters' }),
    phone: z.string().optional(),
    month: z.string().regex(/^(0[1-9]|1[0-2])$/, { message: 'Month must be between 01 and 12' }),
    day: z.string().regex(/^(0[1-9]|[12][0-9]|3[01])$/, { message: 'Day must be between 01 and 31' }),
    year: z
      .string()
      .regex(/^\d{4}$/, { message: 'Year must be a 4-digit number' })
      .refine(
        (val) => {
          const num = parseInt(val, 10);
          return num >= MIN_YEAR && num <= MAX_YEAR;
        },
        { message: `Year must be between ${MIN_YEAR} and ${MAX_YEAR}` },
      ),
  })
  .refine(
    (data) => {
      const { year, month, day } = data;

      // Trim the input strings to remove any accidental whitespace
      const trimmedYear = year.trim();
      const trimmedMonth = month.trim();
      const trimmedDay = day.trim();

      // Convert strings to numbers
      const yearNum = Number(trimmedYear);
      const monthNum = Number(trimmedMonth);
      const dayNum = Number(trimmedDay);

      // Construct the date using numeric parameters
      const date = new Date(yearNum, monthNum - 1, dayNum);

      return date.getFullYear() === yearNum && date.getMonth() + 1 === monthNum && date.getDate() === dayNum;
    },
    {
      message: 'Invalid date of birth',
      path: ['day'], // Attach date-of-birth errors to the 'day' field
    },
  );

export type SettingsFormValues = z.infer<typeof settingsSchema>;
