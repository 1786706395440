import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useRequests from '@/hooks/useRequests.ts';
import ListItem from './ListItem';
import SubscriptionCTA from './SubscriptionCTA';

const List = () => {
  const requests = useRequests();

  // Local state for filtering status
  const [statusFilter, setStatusFilter] = useState<'all' | 'quote' | 'notQuote'>('all');

  // Local state for search
  const [searchTerm, setSearchTerm] = useState('');

  // Filter logic: show based on tab + search term
  const filteredRequests = requests?.data?.filter((request) => {
    // Status filter
    if (statusFilter === 'quote' && request.status !== 'quote') return false;
    if (statusFilter === 'notQuote' && request.status === 'quote') return false;

    // Search filter
    const query = searchTerm.trim().toLowerCase();
    if (!query) return true;

    const requestIdMatch = request.id?.toString().toLowerCase().includes(query);

    // If requests have many medications
    const medicationMatch = request.medications
      ?.map((med) => [med.medication, med.dosage, med.variant, med.generic].filter(Boolean).join(' '))
      .join(' ')
      .toLowerCase()
      .includes(query);

    return requestIdMatch || medicationMatch;
  });

  // Sort after filtering by created_at
  const sortedRequests = filteredRequests
    ?.slice() // copy so we don't mutate original
    .sort((a, b) => {
      const dateA = new Date(a.created_at).valueOf();
      const dateB = new Date(b.created_at).valueOf();
      return dateB - dateA;
    });

  return (
    <>
      <div className="flex flex-col justify-between gap-4 md:items-center sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col justify-between gap-4 md:flex-row sm:gap-8">
          <div className="flex flex-col">
            <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Rx Searches</div>
            <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
              View all of your past searches and their statuses.
            </div>
          </div>
        </div>
        <Button className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap" asChild>
          <Link to="/requests/new">
            <PlusIcon className="w-5 h-5" />
            Start New Search
          </Link>
        </Button>
      </div>

      <div className="flex flex-col justify-between mt-4 lg:gap-4 lg:items-center lg:flex-row">
        {/* Tabs for filtering status */}
        <Tabs value={statusFilter} onValueChange={(val) => setStatusFilter(val as typeof statusFilter)}>
          <TabsList className="my-4">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="quote">Incomplete</TabsTrigger>
            <TabsTrigger value="notQuote">Submitted</TabsTrigger>
          </TabsList>
        </Tabs>

        {/* Search & Sort row */}
        <div className="flex items-center flex-none gap-1 pl-4 pr-2 bg-white border rounded-lg shadow-sm border-neutral-300">
          <Search className="w-5 h-5 text-neutral-500" />
          <Input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none"
          />
          {searchTerm && (
            <Button variant="ghost" onClick={() => setSearchTerm('')}>
              <X className="w-4 h-4 mr-1" />
            </Button>
          )}
        </div>
      </div>

      {/* Render sorted requests */}
      <div className="grid gap-4 mt-4 sm:grid-cols-1 lg:grid-cols-2 lg:mt-0">
        {sortedRequests?.map((request, index) => <ListItem key={request.id} request={request} index={index} />)}
      </div>

      {requests.isLoading && (
        <div className="flex justify-center w-full mb-8">
          <Loading />
        </div>
      )}

      {sortedRequests?.length === 0 && !requests.isLoading && (
        <div className="flex flex-col w-full gap-2 px-2 py-4 text-xs text-center text-gray-600">
          <div>No requests found for these filters.</div>
          <div>Try selecting a different tab, search term, or toggling sort order.</div>
        </div>
      )}

      <div className="my-8">
        <SubscriptionCTA />
      </div>
    </>
  );
};

export default List;
