import { useModal } from '@ebay/nice-modal-react';
import { MapPin, Phone } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { IRequest, ITask } from '@/types';
import { formatPhoneNumber } from '@/utils/helpers';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Button } from '../ui/button';
import { Skeleton } from '../ui/skeleton';
import { StatusIndicator } from './StatusIndicator';

interface MedicationAvailabilityProps {
  request: IRequest;
}

const getStatusPriority = (
  tasks: ITask[],
): 'in_stock' | 'out_of_stock' | 'callback' | 'scheduled' | 'pending' | 'pending_approval' | string | null => {
  if (tasks.some((task) => task.stock_status === 'in_stock')) {
    return 'in_stock';
  }

  if (tasks.some((task) => task.stock_status === 'out_of_stock')) {
    return 'out_of_stock';
  }

  return '';
};

const MedicationAvailability = ({ request }: MedicationAvailabilityProps) => {
  const medicationResultsModal = useModal('medication-results-modal');

  return (
    <div className="space-y-4 col-span-full">
      {request.medications.map((medication) => {
        const medicationTasks = request.tasks.filter((task) => task.medication_id === medication.id);
        const taskWithCalls = medicationTasks.filter((task) => task.calls && task.calls.length > 0);
        const availability = medicationTasks.filter((task) => task.stock_status === 'in_stock');

        return (
          <Card key={medication.id}>
            <CardHeader className="pb-2">
              <CardTitle className="flex items-center justify-between text-lg font-bold sm:text-xl">
                {medication.medication} {medication.dosage}
                <StatusIndicator status={getStatusPriority(medicationTasks)} isOrderLevel={true} />
              </CardTitle>
              <CardDescription>
                {medication.variant} | {medication.dosage} | {medication.quantity}
                {medication.dosage_form}s
              </CardDescription>
            </CardHeader>

            <CardContent className="space-y-4">
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">Pharmacies Searched:</span>
                  <span className="text-sm">
                    {taskWithCalls.length} / {medicationTasks.length}
                  </span>
                </div>
                <Progress value={(taskWithCalls.length / medicationTasks.length) * 100} className="w-full h-2" />
              </div>

              {taskWithCalls.length > 0 ? (
                <div className="mt-4 space-y-2">
                  <p className="text-sm text-muted-foreground">Found at {availability.length} pharmacies</p>
                  <div className="grid gap-2 xl:grid-cols-2 ">
                    {availability.map((availableTask) => (
                      <Card key={`${availableTask.id}-${availableTask.pharmacy_id}`} className="overflow-hidden">
                        <CardHeader className="p-3">
                          <CardTitle className="text-base font-semibold line-clamp-1">
                            {availableTask.pharmacy.name}
                          </CardTitle>
                        </CardHeader>
                        <CardContent className="p-3 pt-0">
                          <div className="space-y-1 text-sm">
                            <div className="flex items-start space-x-2">
                              <MapPin className="h-4 w-4 mt-0.5 flex-shrink-0 " />
                              <span className="capitalize">
                                {availableTask.pharmacy.address1} {availableTask.pharmacy.city}
                              </span>
                            </div>
                            <div className="flex items-start space-x-2">
                              <Phone className="h-4 w-4 mt-0.5 flex-shrink-0" />
                              <span>{formatPhoneNumber(availableTask.pharmacy.phone)}</span>
                            </div>
                          </div>
                        </CardContent>
                        <CardFooter className="justify-between gap-2 p-3 pt-0 my-auto">
                          <Button
                            variant="outline"
                            className="w-full"
                            size="sm"
                            onClick={() => medicationResultsModal.show({ request: request, medication: medication })}
                          >
                            View Details
                          </Button>
                        </CardFooter>
                      </Card>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-full">
                  <div className="flex flex-col space-y-3">
                    <div className="space-y-4">
                      <div className="flex gap-8">
                        <Skeleton className="w-full h-4" />
                        <Skeleton className="w-full h-4" />
                      </div>
                      <div className="flex gap-8">
                        <Skeleton className="w-full h-4" />
                        <Skeleton className="w-full h-4" />
                      </div>
                    </div>
                  </div>
                  <div className="w-full ">
                    <div className="max-w-xl p-2 mx-auto">
                      <Alert variant="destructive">
                        <div className="flex gap-4">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-8 h-8 animate-spin"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                                className="opacity-25"
                              />
                              <path fill="currentColor" d="M12 6a1 1 0 00-1 1v5a1 1 0 002 0V7a1 1 0 00-1-1z" />
                            </svg>
                          </div>
                          <div className="">
                            <AlertTitle>Heads up!</AlertTitle>
                            <AlertDescription>
                              Results will begin to appear here as soon as we find pharmacies that have your medication
                              in stock. To view all pharmacies, click the button below.
                            </AlertDescription>
                          </div>
                        </div>
                      </Alert>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <div className="space-y-4">
                        <div className="flex gap-8">
                          <Skeleton className="w-full h-4" />
                          <Skeleton className="w-full h-4" />
                        </div>
                        <div className="flex gap-8">
                          <Skeleton className="w-full h-4" />
                          <Skeleton className="w-full h-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default MedicationAvailability;
