import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CheckCircle2 } from 'lucide-react';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog.tsx';
import { useToast } from '@/hooks/use-toast';
import { ITask } from '@/types';
import axiosClient from '@/utils/axiosClient';

const TaskTransferModal = NiceModal.create(({ task }: { task: ITask }) => {
  const modal = useModal('task-transfer-modal');
  const { toast } = useToast();

  const confirm = async () => {
    try {
      const response = await axiosClient.put(`/v2/tasks/${task.id}/notify_provider`, {
        notify_provider: true,
      });

      if (response.status === 200) {
        toast({
          title: 'Notification Sent!',
          description: 'Your provider has been sent the details to send the prescription to the selected pharmacy.',
        });
        modal.hide();
      } else {
        toast({
          title: 'Error',
          description: 'There was an error',
        });
      }
    } finally {
      toast({
        title: 'Error',
        description: 'There was an error',
      });
    }
  };

  return (
    <Modal
      modal={modal}
      title={'Notify Provider'}
      description={`Please confirm that you would like to notify your provider to send the prescription to ${task.pharmacy.name}.`}
      wide
    >
      <div className="grid gap-4 ">
        <div className="p-4 rounded-lg bg-muted">
          <h3 className="flex items-center gap-2 mb-2 text-lg font-semibold">
            <CheckCircle2 className="w-5 h-5 text-green-500" />
            Next Steps
          </h3>
          <ol className="space-y-1 text-sm list-decimal list-inside">
            <li>Needle will notify your provider to send the prescription to {task.pharmacy.name}.</li>
            <li>Once sent, if applicable, your provider will give you more information.</li>
          </ol>
        </div>
      </div>
      <DialogFooter className="mt-4 sm:justify-between">
        <Button variant="outline" onClick={() => modal.remove()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            confirm();
          }}
        >
          Confirm Request
        </Button>
      </DialogFooter>
    </Modal>
  );
});

export default TaskTransferModal;
