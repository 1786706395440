import { useState, useRef, useEffect } from 'react';
import { MdLocationPin, MdHelp } from 'react-icons/md';
import LocationInput from '@/components/Location/LocationInput.tsx';
import LocationMap from '@/components/Location/LocationMap.tsx';
import { Card, CardContent } from '@/components/ui/card';
import { IRequest } from '@/types.ts';

interface LocationProps {
  request: IRequest;
}

const Location = ({ request }: LocationProps) => {
  const [showHelp, setShowHelp] = useState(false);
  const helpRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        helpRef.current &&
        !helpRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowHelp(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Card className="p-0">
      <CardContent className="p-0">
        <div className="relative w-full">
          <div className="absolute z-10 w-full top-2">
            <div className="px-2 mx-auto">
              <div className="flex items-center w-full gap-1 px-1 py-1 bg-white rounded-md shadow-md">
                <MdLocationPin className="w-6 h-6 text-brand-purple" />
                <LocationInput request={request} key={request?.location?.id} />
                <button
                  ref={buttonRef}
                  onClick={() => setShowHelp(!showHelp)}
                  className="flex-shrink-0"
                  aria-label="Help"
                >
                  <MdHelp className="w-6 h-6 text-brand-purple" />
                </button>
              </div>
            </div>
          </div>

          {showHelp && (
            <div ref={helpRef} className="absolute z-20 max-w-xs p-4 bg-white rounded shadow-md top-14 right-2">
              <h3 className="mb-2 font-bold">How to use the map:</h3>
              <ul className="pl-4 text-sm list-disc">
                <li>Enter an address or drag the pin to set your location.</li>
                <li>The purple circle shows your search area.</li>
                <li>Adjust the circle's edge or use the slider below to change your search range.</li>
                <li>Pharmacy icons will appear within your search area if available.</li>
              </ul>
            </div>
          )}

          <LocationMap request={request} key={request?.location?.id} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Location;
