import { useState, useRef, useEffect } from 'react';
import { APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { MdOutlineMyLocation, MdHelp } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';
import { Circle } from '@/components/Location/Circle';
import PharmacyIcon from '@/components/PharmacyIcon';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Slider } from '@/components/ui/slider';
import usePharmacyFinder from '@/hooks/usePharmacyFinder.ts';
import { IPharmacy, IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';

const MILES_TO_METERS = 1609.34;
const milesToMeters = (miles: number) => miles * MILES_TO_METERS;
const metersToMiles = (meters: number) => meters / MILES_TO_METERS;

const PREPOPULATED_MILES = [1, 3, 5, 7, 10, 15, 20, 25, 50, 75, 100, 150, 300];

interface MarkerWithInfowindowProps {
  pharmacy: IPharmacy;
}

const MarkerWithInfowindow = ({ pharmacy }: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(!infowindowOpen)}
        position={{
          lat: parseFloat(String(pharmacy.latitude)),
          lng: parseFloat(String(pharmacy.longitude)),
        }}
        title={pharmacy.name}
      >
        <PharmacyIcon pharmacy={pharmacy} />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow anchor={marker} maxWidth={225} onCloseClick={() => setInfowindowOpen(false)}>
          <div className="mb-2 text-sm font-semibold text-pretty">{pharmacy.name}</div>
          <div className="text-sm text-gray-600 text-pretty">{pharmacy.address1}</div>
          <div className="text-sm text-gray-600 text-pretty">
            {pharmacy.city}, {pharmacy.zip}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

// ─────────────────────────────────────────────────────────────────────────────────
// Helper: zoom-level calculation
const calculateZoomLevel = (radiusInMeters: number) => {
  const buffer = 1500;
  const windowWidth = 500;

  const scale = 2;
  const zoom = 15;
  const radius = (radiusInMeters + buffer) * scale;
  const zoomHeight = Math.round(
    Math.log2((windowWidth * 360) / (radius * 256 * Math.cos((Math.PI / 180) * 37.7749))) + zoom,
  );
  return zoomHeight > 0 ? zoomHeight : 1;
};

// ─────────────────────────────────────────────────────────────────────────────────
// Main component
interface LocationMapProps {
  request: IRequest;
}

const LocationMap = ({ request }: LocationMapProps) => {
  const pharmacyFinder = usePharmacyFinder(request);
  const queryClient = useQueryClient();
  const mapRef = useRef<google.maps.Map | null>(null);

  // Center is stored in lat/lng
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: request.location?.latitude || 37.7749,
    lng: request.location?.longitude || -122.4194,
  } as google.maps.LatLngLiteral);

  // Radius is stored in meters
  const [radius, setRadius] = useState(request.radius);
  const [radiusSlider, setRadiusSlider] = useState([radius]);
  const [calculatedZoomHeight, setCalculatedZoomHeight] = useState(calculateZoomLevel(request.radius));
  const [zoom, setZoom] = useState(calculatedZoomHeight);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const hasUsedMapBefore = localStorage.getItem('hasUsedMap');
    if (!hasUsedMapBefore) {
      setShowInstructions(true);
      setShowTooltip(true);
      localStorage.setItem('hasUsedMap', 'true');
    }
  }, []);

  const handleMapInteraction = () => {
    setShowInstructions(false);
    setShowTooltip(false);
  };

  // Update radius on server
  const handleRadiusChange = (newRadiusInMeters: number) => {
    axiosClient
      .put(`/v1/requests/${request.id}`, {
        radius: newRadiusInMeters,
      })
      .then((response) => {
        setRadius(newRadiusInMeters);
        queryClient.setQueryData([`v1/requests/${request.id}`], response.data);
        pharmacyFinder.refetch();
        setCalculatedZoomHeight(calculateZoomLevel(response.data.radius));
      });
  };

  // When user adjusts radius on slider
  const handleSliderChange = (newRadius: [number]) => {
    setRadiusSlider(newRadius);
    // Only call handleRadiusChange on commit if you'd prefer (once they let go of slider)
  };

  // When slider is released (committed)
  const handleSliderCommit = (newRadius: [number]) => {
    setRadiusSlider(newRadius);
    handleRadiusChange(newRadius[0]);
  };

  // If user picks from select
  const handleSelectChange = (value: string) => {
    const miles = parseFloat(value);
    const meters = milesToMeters(miles);
    setRadiusSlider([meters]);
    handleRadiusChange(meters);
  };

  const pharmacies = pharmacyFinder?.data || [];

  // Move the center when user drags the circle’s center or moves the marker
  const changeCenter = (newCenter: any) => {
    if (!newCenter) return;
    const payload = {
      request_id: request.id,
      latitude: newCenter.latLng?.lat(),
      longitude: newCenter.latLng?.lng(),
    };

    setCenter({
      lat: payload.latitude,
      lng: payload.longitude,
    });

    axiosClient.post(`/v1/locations`, payload).then((response) => {
      queryClient.setQueryData([`v1/requests/${request.id}`], response.data);
      pharmacyFinder.refetch();
      setCalculatedZoomHeight(calculateZoomLevel(response.data.radius));
    });
  };

  // This debounced version is used if you want some delay, but you can remove it if you prefer
  const debouncedChangeCenter = useDebouncedCallback(handleRadiusChange, 250);

  // Derived miles value
  const radiusInMiles = Math.round(metersToMiles(radiusSlider[0]) * 100) / 100;

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom()!);
    }
  };

  return (
    <div className="relative">
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <Map
          defaultCenter={center}
          mapId="bf51a910020fa25a"
          className="h-[350px] md:rounded-lg"
          defaultZoom={zoom}
          gestureHandling="greedy"
          disableDefaultUI
          onZoomChanged={handleZoomChanged}
          onClick={handleMapInteraction}
          onDragstart={handleMapInteraction}
        >
          {showInstructions && (
            <div className="absolute z-10 p-2 text-sm text-white bg-black rounded bottom-4 left-4 right-4 bg-opacity-70">
              Enter an address or drag the pin to see nearby pharmacies. Adjust the radius to expand your search.
            </div>
          )}

          {pharmacies.map((pharmacy) => (
            <MarkerWithInfowindow key={pharmacy.id} pharmacy={pharmacy} />
          ))}

          <Circle
            radius={radiusSlider[0]}
            center={center}
            onRadiusChanged={(newRadius) => {
              // For immediate changes, set slider & call radius change
              setRadiusSlider([newRadius]);
              debouncedChangeCenter(newRadius);
              handleMapInteraction();
            }}
            onDragEnd={(e) => {
              changeCenter(e);
              handleMapInteraction();
            }}
            strokeColor="#8D5BFF"
            strokeOpacity={1}
            strokeWeight={2}
            fillColor="#8D5BFF"
            fillOpacity={0.16}
            editable
            draggable
          />

          <AdvancedMarker position={center} title="Request Location" onClick={handleMapInteraction}>
            <MdOutlineMyLocation className="w-8 h-8 -mb-4 text-brand-purple" />
            {showTooltip && (
              <div className="absolute left-1/2 transform -translate-x-1/2 -top-10 bg-violet-500 text-white px-3 py-1.5 rounded-md shadow-md text-sm font-medium whitespace-nowrap">
                Drag me to search!
                <div className="absolute bottom-0 w-2 h-2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-purple-600 left-1/2" />
              </div>
            )}
          </AdvancedMarker>
        </Map>
      </APIProvider>

      <div className="flex items-center w-full gap-4 pt-4 pb-2">
        <div className="flex-none pl-2 text-sm text-gray-600">Range</div>
        <div className="grow">
          <Slider
            // If you want up to 300 miles (≈ 482,800m), set max to something like 500k
            max={500000}
            step={200}
            value={radiusSlider}
            onValueChange={handleSliderChange}
            onValueCommit={handleSliderCommit}
          />
        </div>

        {/* Replace the badge with a select list. */}
        <div className="flex-none w-[120px]">
          <Select value={String(radiusInMiles)} onValueChange={handleSelectChange}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Miles" />
            </SelectTrigger>
            <SelectContent>
              {!PREPOPULATED_MILES.includes(radiusInMiles) && (
                <SelectItem value={String(radiusInMiles)}>{String(radiusInMiles)} Miles</SelectItem>
              )}
              {PREPOPULATED_MILES.map((milesOption) => (
                <SelectItem key={milesOption} value={String(milesOption)}>
                  {milesOption} Miles
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <button
        className="absolute p-1 text-xs bg-white rounded-full shadow-md top-2 right-2"
        onClick={() => setShowInstructions(true)}
      >
        <MdHelp className="w-5 h-5 text-brand-purple" />
      </button>
    </div>
  );
};

export default LocationMap;
