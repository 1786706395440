import { Plus, Redo2 } from 'lucide-react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router';
import { Button } from '@/components/ui/button.tsx';
import { Card, CardHeader } from '@/components/ui/card';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient';
import Archive from './Archive.tsx';

const Actions = ({ request }: { request: IRequest }) => {
  const navigate = useNavigate();
  const handleClone = () => {
    posthog.capture('clone_search');
    axiosClient.post(`/v1/requests?last_request_id=${request.id}`).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  };

  const handleFresh = () => {
    posthog.capture('new_search_created');
    axiosClient.post(`/v1/requests?fresh=true`).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  };

  return (
    <Card>
      <CardHeader>
        <div className="grid items-center justify-center grid-cols-1 gap-4 lg:grid-cols-1">
          <Button variant="outline" className="w-full" onClick={handleFresh}>
            <Plus className="h-4 text-purple-900" /> Start New Search
          </Button>
          <Button variant="outline" className="w-full" onClick={handleClone}>
            <Redo2 className="h-4 text-purple-900" /> Search Again
          </Button>

          <Archive requestId={request.id} />
        </div>
      </CardHeader>
    </Card>
  );
};

export default Actions;
