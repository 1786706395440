import { useEffect, useState } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { Search, ChevronDown, X, Star } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Loading from '@/components/Loading';
import PharmacyIcon from '@/components/PharmacyIcon';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';
import { IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';
import Phone from '../Phone';
import { Card, CardContent } from '../ui/card';

function MyPharmacies() {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [filter, setFilter] = useState<'favorites' | 'all'>('favorites');
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading: isPharmaciesLoading } = useQuery(
    ['pharmacies', currentPage, searchQuery, sortColumn, sortOrder, pageSize, filter],
    () =>
      axiosClient.get('/v1/user_favorite_pharmacies', {
        params: {
          page: currentPage + 1,
          per_page: pageSize,
          query: searchQuery,
          sort: sortOrder,
          sortColumn,
          filter,
        },
      }),
    {
      keepPreviousData: true,
      onError: (error) => {
        console.error('Error fetching pharmacies:', error);
        toast({
          title: 'Error',
          description: 'Failed to load pharmacies. Please try again.',
          variant: 'destructive',
        });
      },
    },
  );

  // If 'favorites' is selected but there are no favorite pharmacies, switch to 'all'
  useEffect(() => {
    if (!isPharmaciesLoading && filter === 'favorites') {
      const favoritesCount = data?.data?.totalCount || 0;
      if (favoritesCount === 0) {
        setFilter('all');
      }
    }
  }, [filter, isPharmaciesLoading, data?.data?.totalCount]);

  const toggleFavoriteMutation = useMutation(
    (pharmacyId: string) =>
      axiosClient.post('/v1/user_favorite_pharmacies/toggle', {
        pharmacy_id: pharmacyId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pharmacies');
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update favorite. Please try again.',
          variant: 'destructive',
        });
      },
    },
  );

  const pharmacies = data?.data?.pharmacies || [];
  const totalPages = data?.data?.totalPages || 1;
  const totalCount = data?.data?.totalCount || 0;

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  const handleNextPage = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    setCurrentPage(0);
  };

  const columns: ColumnDef<IPharmacy>[] = [
    {
      accessorKey: 'name',
      header: () => (
        <Button variant="ghost" onClick={() => handleSort('name')} className="font-semibold">
          Name
          <ChevronDown
            className={`ml-2 h-4 w-4 transform ${sortColumn === 'name' && sortOrder === 'desc' ? 'rotate-180' : ''}`}
          />
        </Button>
      ),
      cell: (info) => (
        <div className="flex items-center space-x-3">
          <PharmacyIcon pharmacy={info.row.original} />
          <span className="font-semibold text-gray-900">{info.getValue<string>()}</span>
        </div>
      ),
    },
    {
      accessorKey: 'phone',
      header: () => (
        <Button variant="ghost" onClick={() => handleSort('phone')} className="font-semibold">
          Phone
        </Button>
      ),
      cell: (info) => <Phone>{info.getValue<string>()}</Phone>,
    },
    {
      accessorKey: 'address',
      header: 'Address',
      cell: (info) => {
        const pharmacy = info.row.original;
        return `${pharmacy.address1}, ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip}`;
      },
    },
    {
      id: 'favorite',
      header: 'Favorite',
      cell: (info) => {
        const pharmacy = info.row.original;
        return (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => toggleFavoriteMutation.mutate(pharmacy.id)}
            className="bg-none"
          >
            <Star
              className={cn('w-4 h-4', pharmacy.is_favorited ? 'text-yellow-600' : 'text-gray-400')}
              fill={pharmacy.is_favorited ? 'yellow' : 'none'}
            />
          </Button>
        );
      },
    },
  ];

  const table = useReactTable({
    data: pharmacies,
    columns,
    pageCount: totalPages,
    state: {
      pagination: { pageIndex: currentPage, pageSize },
    },
    onPaginationChange: ({ pageIndex, pageSize }: any) => {
      setCurrentPage(pageIndex);
      setPageSize(pageSize);
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const paginationSummary =
    totalCount > 0
      ? `Showing ${currentPage * pageSize + 1} - ${Math.min(
          (currentPage + 1) * pageSize,
          totalCount,
        )} of ${totalCount} results`
      : 'No results found';

  return (
    <>
      <div className="flex flex-col justify-between gap-4 md:items-center sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col justify-between gap-4 md:flex-row sm:gap-8">
          <div className="flex flex-col">
            <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Manage your pharmacies</div>
            <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
              View and manage your pharmacies that you prefer to use for your prescriptions.
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between mt-4 lg:gap-4 lg:items-center lg:flex-row">
        {/* Tabs for filtering favorites/all */}
        <Tabs value={filter} onValueChange={(val) => setFilter(val as 'favorites' | 'all')}>
          <TabsList className="my-4">
            <TabsTrigger value="favorites">Favorites</TabsTrigger>
            <TabsTrigger value="all">All Pharmacies</TabsTrigger>
          </TabsList>
        </Tabs>

        {/* Search bar */}
        <div className="flex gap-4 flex-center">
          <div className="inline-flex items-center w-full gap-1 pl-4 pr-2 bg-white border rounded-lg shadow-sm sm:w-auto border-neutral-200">
            <Search className="w-5 h-5 text-neutral-500" />
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none"
            />
            {searchQuery && (
              <Button variant="ghost" onClick={() => setSearchQuery('')}>
                <X className="w-4 h-4 mr-1" />
              </Button>
            )}
          </div>
        </div>
      </div>

      <Card className="mt-4 lg:mt-0">
        <CardContent className="p-0">
          {isPharmaciesLoading ? (
            <Loading text="Loading pharmacies..." />
          ) : (
            <div className="overflow-hidden bg-white rounded-lg shadow-sm">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="text-center">
                        No pharmacies found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-600">Page Size:</span>
          <Select value={String(pageSize)} onValueChange={(value) => handlePageSizeChange(Number(value))}>
            <SelectTrigger className="w-32">
              <SelectValue placeholder={`${pageSize}`} />
            </SelectTrigger>
            <SelectContent>
              {[5, 10, 25, 50, 100].map((size) => (
                <SelectItem key={size} value={String(size)}>
                  {size} per page
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <span className="text-sm text-gray-600">{paginationSummary}</span>
        <div className="flex items-center gap-2">
          <Button variant="ghost" disabled={currentPage === 0} onClick={handlePreviousPage}>
            Previous
          </Button>
          <span>
            Page {currentPage + 1} of {totalPages}
          </span>
          <Button variant="ghost" disabled={currentPage + 1 >= totalPages} onClick={handleNextPage}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default MyPharmacies;
