import { AlertCircle, Pill, Recycle, Search, Store } from 'lucide-react';
import posthog from 'posthog-js';
import { FaUserDoctor } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';
import StatusBadge from '../StatusBadge';
import Time from '../Time';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Separator } from '../ui/separator';

const ListItem = ({ request }: { request: IRequest; index: number }) => {
  const navigate = useNavigate();

  const isQuote = request.status === 'quote';
  const hasTasks = request.tasks.length > 0;

  const handleClone = () => {
    posthog.capture('clone_search');
    axiosClient.post(`/v1/requests?last_request_id=${request.id}`).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  };

  return (
    <>
      <Card key={request.id} className="flex flex-col ">
        <CardHeader>
          <CardTitle>
            <div className="flex justify-between ">
              <div className="text-neutral-700">
                Rx #: <Link to={`/requests/${request.id}`}>{request.id.slice(0, 6).toUpperCase()}</Link>
              </div>

              <StatusBadge statusDetails={request.status_details} />
            </div>
          </CardTitle>
          <CardDescription>
            <div className="">
              <Time>{request.created_at}</Time>
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent className="flex-grow pt-0">
          <div className="flex justify-between mb-3 text-sm">
            <div className="flex items-center">
              <Pill className="w-4 h-4 mr-1" />
              <span>
                {request.medications.length} Medication{request.medications.length !== 1 ? 's' : ''}
              </span>
            </div>
            <div className="flex items-center">
              <Store className="w-4 h-4 mr-1" />
              <span>
                {request.pharmacies.length} Pharmac{request.pharmacies.length !== 1 ? 'ies' : 'y'}
              </span>
            </div>
          </div>
          {request.medications.map((medication) => (
            <div key={medication.id} className="flex items-center gap-4 text-xl font-medium">
              <div>{medication.short_name}</div>
            </div>
          ))}
          <Separator className="mt-2" />
          <p className="mt-2 text-xs text-muted-foreground line-clamp-3">{request.status_details.text}</p>

          <Separator className="mt-2" />

          {request.account && (
            <div className="flex items-center my-2 text-sm" title={`This was prescribed by ${request.account.name}`}>
              <FaUserDoctor className="w-4 h-4 mr-1" />
              <span>Prescriber: {request.account.name}</span>
            </div>
          )}
        </CardContent>
        <CardFooter className={cn('flex item', isQuote ? 'justify-end' : 'justify-between')}>
          {hasTasks && (
            <AlertDialog>
              <AlertDialogTrigger>
                <Button size="sm" variant={'outline'} title={'Clone this search'}>
                  <Recycle size={16} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Clone search</AlertDialogTitle>
                  <AlertDialogDescription>
                    Would you like to clone this search and start a new one? This will copy all medications and
                    pharmacies selected.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleClone}>Continue</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}

          <Button size="sm" className={cn('gap-2', isQuote ? 'bg-red-400 ' : '  text-white')} asChild>
            <Link to={`/requests/${request.id}`} className="gap-2 ">
              {isQuote ? <AlertCircle size={16} /> : <Search size={16} />}
              {isQuote ? 'Complete Request' : 'View Results'}
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ListItem;
