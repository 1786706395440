export interface HolidayAlert {
  startDate: string;
  endDate: string;
  message: string;
}

export const holidayAlerts: HolidayAlert[] = [
  // Independence Day
  {
    startDate: '2025-07-03',
    endDate: '2025-07-04',
    message: 'Needle will be closed on July 4th in observance of Independence Day. Happy 4th! 🎆 🎇',
  },

  // Thanksgiving
  {
    startDate: '2025-11-26',
    endDate: '2025-11-27',
    message: 'Needle will be closed November 27th for Thanksgiving. Happy Thanksgiving! 🦃',
  },
  // Christmas
  {
    startDate: '2025-12-24',
    endDate: '2025-12-25',
    message: 'Needle will be closed December 24-25th for Christmas. Happy Holidays! 🎄',
  },
  // New Year's Eve/Day 2026
  {
    startDate: '2025-12-31',
    endDate: '2026-01-01',
    message: "Needle will be closed January 1st for New Year's. Happy New Year! ✨🎉",
  },
];
