import { IDrugRecord } from '@/types';
import { getUrl } from '@/utils/api';

function useDrugRecords(filter?: string) {
  let url = 'v2/drugs';
  if (filter) {
    url += `?query=${filter}`;
  }

  const query = getUrl(url);

  return {
    ...query,
    data: query.data as IDrugRecord[],
  };
}

export default useDrugRecords;
