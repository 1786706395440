import React, { useState, useEffect } from 'react';
import Logopin from '@/assets/logo-wordmark-white.svg';
import { holidayAlerts } from '@/holidayAlerts';

// const getMockDate = () => {
//   return new Date('2025-12-24'); // Will show the Christmas message for testing purposes, swap the const now in CheckHolidayAlert as well.
// };

const AlertBannerHoliday: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<string>('');

  useEffect(() => {
    const checkHolidayAlert = () => {
      const now = new Date();
      //const now = getMockDate();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      const currentAlert = holidayAlerts.find((alert) => {
        const start = new Date(alert.startDate);
        const end = new Date(alert.endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        return today >= start && today <= end;
      });

      if (currentAlert) {
        setCurrentMessage(currentAlert.message);
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    checkHolidayAlert();

    const getMsUntilMidnight = () => {
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      return tomorrow.getTime() - now.getTime();
    };

    const timeout = setTimeout(() => {
      checkHolidayAlert();
      const interval = setInterval(checkHolidayAlert, 24 * 60 * 60 * 1000);
      return () => clearInterval(interval);
    }, getMsUntilMidnight());

    return () => clearTimeout(timeout);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed left-0 right-0 z-50 flex items-center justify-center px-4 top-4">
      <div
        className="flex items-center justify-between w-full max-w-3xl px-6 py-3 
        bg-[rgb(141,91,255)] rounded-lg shadow-xl
        animate-fadeIn border-2 border-yellow-300"
        style={{
          backgroundImage:
            'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255,223,0,0.1) 10px, rgba(255,223,0,0.1) 20px)',
        }}
      >
        <div className="flex items-center gap-6 flex-1">
          <img src={Logopin} alt="Needle Logo" className="w-12 h-12" />
          <p className="text-sm sm:text-base text-white font-medium text-center flex-1">{currentMessage}</p>
        </div>
        <button
          onClick={() => setIsVisible(false)}
          className="ml-4 p-1 text-red-500 hover:text-red-400 transition-colors
          focus:outline-none focus:ring-2 focus:ring-white/20 rounded"
          aria-label="Close alert"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AlertBannerHoliday;
