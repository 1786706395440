import { useState, useEffect, memo } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Edit, Plus, PlusIcon, Search, Tag, X } from 'lucide-react';
import { FaUserDoctor } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import SubscriptionCTA from '@/components/Requests/SubscriptionCTA';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { IMedication } from '@/types';
import { USDollar } from '@/utils/helpers';

interface MedicationCardProps {
  medication: IMedication;
  onEdit: (medication: IMedication) => void;
}

const MedicationCard = memo(({ medication, onEdit }: MedicationCardProps) => {
  const navigate = useNavigate();
  const onNewSearch = (med: IMedication) => {
    navigate(`/requests/new?medication_id=${med.id}`);
  };

  const preferenceText = {
    Generic: 'Generic preferred',
    Brand: 'Brand name preferred',
    'No Preference': 'No Preference',
  };

  return (
    <Card>
      <CardHeader className="flex flex-col">
        <div className="flex items-center justify-between gap-4">
          <CardTitle className="text-neutral-700">
            {medication.medication} {medication.dosage}
          </CardTitle>

          {medication.label && (
            <Badge variant="secondary" className="text-xs border-gray-300">
              <Tag className="w-3 h-3 mr-1" />
              {medication.label}
            </Badge>
          )}
        </div>
        <CardDescription>{medication.variant}</CardDescription>
      </CardHeader>

      <CardContent>
        <div className="flex items-center gap-4 text-sm">
          <span className="text-gray-600">Quantity:</span>
          <span className="font-semibold">
            {medication.quantity} {medication.dosage_form}
          </span>
        </div>
        <div className="flex items-center gap-4 mt-2 text-sm">
          <span className="text-gray-600">Preference:</span>
          <div className="font-semibold">
            {preferenceText[medication.preference as 'Generic' | 'Brand' | 'No Preference']}
          </div>
        </div>

        {medication.account_id && (
          <>
            <Separator className="my-2" />
            <div className="flex flex-col gap-2 md:flex-row">
              <Badge className="gap-2">
                <FaUserDoctor className="w-2.5 h-2.5" />
                Provider: {medication.account?.owner.name} | {medication.account?.name}
              </Badge>
              <Badge variant={'outline'} className="gap-1 text-gray-600 border-gray-300">
                {medication.account?.provider_type === 'covered' ? (
                  'Costs Covered by Provider'
                ) : (
                  <>{USDollar.format(parseFloat(String(medication.account?.provider_pharmacy_cost)))} Per Call</>
                )}
              </Badge>
            </div>
            <Separator className="my-2" />
          </>
        )}
      </CardContent>
      <Separator />

      <CardFooter className="flex justify-between p-3">
        <Button variant="outline" onClick={() => onNewSearch(medication)}>
          <Plus className="w-4 h-4 mr-2" />
          Start Search
        </Button>
        <Button onClick={() => onEdit(medication)}>
          <Edit className="w-4 h-4 mr-2" />
          Edit Medication
        </Button>
      </CardFooter>
    </Card>
  );
});

const Medications = ({ medications }: { medications: IMedication[] }) => {
  const medicationModal = useModal('medication-modal');

  // Active tab (all or specific medication name)
  const [activeTab, setActiveTab] = useState<string>('all');

  // Search + label filters
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  // Unique labels & med names
  const allMedicationNames = [...new Set(medications.map((med) => med.medication))].sort();

  // Entire filtered medication list based on activeTab, search, labels
  const [filteredMedications, setFilteredMedications] = useState<IMedication[]>([]);

  // Filter logic: runs whenever user changes tab, search, or labels
  useEffect(() => {
    let results = [...medications];

    // Tab filter: if not "all," filter to that medication’s name
    if (activeTab !== 'all') {
      results = results.filter((m) => m.medication === activeTab);
    }

    // Label filter
    if (selectedLabels.length > 0) {
      results = results.filter((m) => m.label && selectedLabels.includes(m.label));
    }

    // Search filter: check short_name or label
    if (searchTerm.trim().length > 0) {
      const query = searchTerm.toLowerCase();
      results = results.filter(
        (m) => m.short_name.toLowerCase().includes(query) || (m.label && m.label.toLowerCase().includes(query)),
      );
    }

    setFilteredMedications(results);
  }, [medications, activeTab, searchTerm, selectedLabels]);

  /* 
    Helper function to calculate the number of medications 
    in a "tab" given the current searchTerm + label filters.
  */
  const getCountForTab = (tabName: string) => {
    let results = [...medications];

    if (tabName !== 'all') {
      results = results.filter((m) => m.medication === tabName);
    }

    if (selectedLabels.length > 0) {
      results = results.filter((m) => m.label && selectedLabels.includes(m.label));
    }

    if (searchTerm.trim().length > 0) {
      const query = searchTerm.toLowerCase();
      results = results.filter(
        (m) => m.short_name.toLowerCase().includes(query) || (m.label && m.label.toLowerCase().includes(query)),
      );
    }

    return results.length;
  };

  // Edit callback
  const handleEdit = (med: IMedication) => {
    medicationModal.show({
      request: null,
      medication: med,
      medications,
    });
  };

  // Toggle a label
  const handleLabelToggle = (label: string) => {
    setSelectedLabels((prev) => (prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]));
  };

  return (
    <>
      <div className="flex flex-col justify-between gap-4 md:items-center sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col justify-between gap-4 md:flex-row sm:gap-8">
          <div className="flex flex-col">
            <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Medications</div>
            <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
              Organize your medication list and keep track of your prescriptions with Needle.
            </div>
          </div>
        </div>

        <Button
          className="items-center w-full gap-2 transition-all duration-300 rounded-full shadow-sm hover:shadow-md sm:w-auto"
          onClick={() =>
            medicationModal.show({
              medications: filteredMedications,
              defaultPath: 'newMedication',
            })
          }
        >
          <PlusIcon className="w-6 h-6" />
          Add Medication
        </Button>
      </div>

      <div className="flex flex-col justify-between mt-4 lg:gap-4 lg:items-center lg:flex-row">
        <div className="">
          {/* MOBILE: Replace tabs with a Select */}
          <div className="block my-4 md:hidden">
            <Select value={activeTab} onValueChange={setActiveTab}>
              <SelectTrigger>
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All ({getCountForTab('all')})</SelectItem>
                {allMedicationNames.map((name) => (
                  <SelectItem key={name} value={name}>
                    {name} ({getCountForTab(name)})
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* DESKTOP: Original Tabs */}
          <div className="hidden my-4 lg:block">
            <Tabs value={activeTab} onValueChange={setActiveTab}>
              <TabsList className="flex my-4 overflow-x-auto">
                <TabsTrigger value="all">All ({getCountForTab('all')})</TabsTrigger>
                {allMedicationNames.map((name) => (
                  <TabsTrigger key={name} value={name}>
                    {name} ({getCountForTab(name)})
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
        </div>
        <div className="">
          {/* Search & Filter Controls */}
          <div className="flex items-center flex-none gap-1 pl-4 pr-2 bg-white border rounded-lg shadow-sm border-neutral-300">
            <Search className="w-5 h-5 text-neutral-500" />
            <Input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none"
            />
            {searchTerm && (
              <Button variant="ghost" onClick={() => setSearchTerm('')}>
                <X className="w-4 h-4 mr-1" />
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Selected labels as badges */}
      {selectedLabels.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedLabels.map((label) => (
            <Badge key={label} variant="secondary" className="px-2 py-1">
              {label}
              <Button
                variant="ghost"
                size="sm"
                className="h-auto p-0 ml-2 text-muted-foreground"
                onClick={() => handleLabelToggle(label)}
              >
                <X className="w-3 h-3" />
                <span className="sr-only">Remove {label} filter</span>
              </Button>
            </Badge>
          ))}
        </div>
      )}

      {/* Medication Cards */}
      <div className="grid gap-4 mt-4 lg:grid-cols-2 lg:mt-0">
        {filteredMedications.map((med) => (
          <MedicationCard key={med.id} medication={med} onEdit={handleEdit} />
        ))}
      </div>

      {/* Empty state */}
      {!filteredMedications.length && (
        <div className="p-8 mt-8 text-center text-gray-500 rounded-lg shadow-inner bg-gray-50">
          <p className="mb-3 text-xl font-medium">
            {searchTerm.length > 0 ? 'No medications found' : 'You have not added any medications'}
          </p>
          <p className="mb-4 text-sm">
            {searchTerm.length > 0
              ? 'Try adjusting your search terms'
              : 'Click the "Add Medication" button to get started'}
          </p>
          {!searchTerm.length && (
            <Button
              className="mt-2"
              onClick={() =>
                medicationModal.show({
                  medications: filteredMedications,
                  defaultPath: 'newMedication',
                })
              }
            >
              <PlusIcon className="w-5 h-5 mr-2" />
              Add Your First Medication
            </Button>
          )}
        </div>
      )}

      <div className="my-8">
        <SubscriptionCTA />
      </div>
    </>
  );
};

export default Medications;
