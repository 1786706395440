import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { MessageSquare } from 'lucide-react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import useMe from '@/hooks/useMe';
import { smsSubscribeSchema, SMSSubscribeFormValues } from '@/schema/smsSubscribeSchema';
import axiosClient from '@/utils/axiosClient.ts';

const SMSSubscription = () => {
  const { toast } = useToast();
  const [saving, setSaving] = useState(false);
  const currentUser = useMe();

  const form = useForm<SMSSubscribeFormValues>({
    resolver: zodResolver(smsSubscribeSchema),
    defaultValues: {
      phone: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (data: SMSSubscribeFormValues) => {
    setSaving(true);

    try {
      const response = await axiosClient.put(`/v1/users/${currentUser?.data?.id}/sms`, data);
      setSaving(false);

      if (response.data.phone) {
        currentUser.data.phone = response.data.phone;
        toast({
          title: 'Subscibed!',
          description: 'You will now receive SMS updates about your medication availability.',
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'You may already be subscribed to SMS updates.',
        });
      }
    } catch (error) {
      setSaving(false);
      console.error('Failed to update user data', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Could not save your phone number. Please try again.',
      });
    }
  };

  // If the user already has a phone number, don't show the form
  if (currentUser?.data?.phone) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl font-bold">Get SMS Updates</CardTitle>
        <CardDescription>Receive notifications about your medication availability via SMS</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              control={control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FormControl>
                    <InputMask
                      mask="(999) 999-9999"
                      {...field}
                      id="phone"
                      placeholder="Enter your phone number"
                      onChange={(e) => field.onChange(e.target.value)}
                      className="text-center placeholder:text-left"
                    >
                      {(inputProps: any) => <Input type="tel" {...inputProps} />}
                    </InputMask>
                  </FormControl>
                  {errors.phone && <FormMessage>{errors.phone.message}</FormMessage>}
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full mt-4" variant="pink" disabled={saving}>
              {saving ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" className="opacity-25" />
                    <path fill="currentColor" d="M12 6a1 1 0 00-1 1v5a1 1 0 002 0V7a1 1 0 00-1-1z" />
                  </svg>
                  Subscribing...
                </>
              ) : (
                <>
                  <MessageSquare className="w-4 h-4 mr-2" /> Subscribe
                </>
              )}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default SMSSubscription;
