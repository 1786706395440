import IntercomWrapper from '@/components/IntercomWrapper';

type Props = {
  children: React.ReactNode;
};

const IntercomProvider = ({ children }: Props) => {
  return <IntercomWrapper>{children}</IntercomWrapper>;
};

export default IntercomProvider;
