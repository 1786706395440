import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import useMe from '@/hooks/useMe';
import AppSidebar from './AppSidebar';
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from './ui/breadcrumb';
import { Separator } from './ui/separator';
import { Sidebar, SidebarInset, SidebarProvider, SidebarTrigger } from './ui/sidebar';

interface BreadcrumbMap {
  [key: string]: string;
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const currentUser = useMe();
  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/').filter((segment) => segment);

  const [isOpen, setOpen] = useState(true);

  const breadcrumbNameMap: BreadcrumbMap = {
    requests: 'Rx',
    profile: 'Profile',
    settings: 'Settings',
    dashboard: 'Dashboard',
    users: 'Users',
    memberships: 'Pricing',
    'my-pharmacies': 'Pharmacies',
  };

  // Helper function to check if a string is a valid UUID
  const isUUID = (str: string) => {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(str);
  };

  const breadcrumbs = [
    { href: '/', label: 'Needle' },
    ...pathSegments.map((segment, index) => {
      const href = '/' + pathSegments.slice(0, index + 1).join('/');
      let label = breadcrumbNameMap[segment] || capitalize(segment);

      // Check if the previous segment is 'requests' and current segment is a UUID
      if (index > 0 && pathSegments[index - 1] === 'requests' && isUUID(segment)) {
        label = '#' + segment.substring(0, 6).toUpperCase();
      }

      return {
        href,
        label,
      };
    }),
  ];

  const toggleSidebar = () => {
    setOpen(!isOpen);
  };

  function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  return (
    <SidebarProvider defaultOpen={isOpen}>
      <Sidebar collapsible="icon">
        {currentUser.isLoading || currentUser.data === null ? (
          <div>Loading...</div>
        ) : (
          <AppSidebar currentUser={currentUser.data} toggleSidebar={toggleSidebar} />
        )}
      </Sidebar>
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="h-4 mr-2" />
            <Breadcrumb>
              <BreadcrumbList>
                {breadcrumbs.map((crumb, index) => (
                  <BreadcrumbItem key={crumb.href}>
                    <Link to={crumb.href}>{crumb.label}</Link>
                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </BreadcrumbItem>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex flex-col flex-1 gap-4 ">
          <div className="min-h-[100vh] flex-1 rounded-xl md:min-h-min flex-col gap-4 p-4 pt-0">{children}</div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
