import { useState } from 'react';
import { FireIcon } from '@heroicons/react/16/solid';
import { Clock } from 'lucide-react';
import { useQueryClient, useMutation } from 'react-query';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import useMe from '@/hooks/useMe';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import { USDollar } from '@/utils/helpers';
import processingOptions from '@/utils/processingOptions';
import { Switch } from '../ui/switch';

interface RequestSummaryProps {
  request: IRequest;
}

const ProcessingTimes = ({ request }: RequestSummaryProps) => {
  const currentUser = useMe();
  const isMember = currentUser?.data?.membership_is_active;
  const queryClient = useQueryClient();

  const [processingTime, setProcessingTime] = useState(request.processing_time || '1-2 Business Days');

  const updateProcessingTimeMutation = useMutation(
    (newProcessingTime: string) =>
      axiosClient.post(`/v1/requests/${request.id}/processing_time`, {
        request: { processing_time: newProcessingTime },
      }),

    {
      onSuccess: (response) => {
        const updatedRequest = response.data as IRequest;
        updatedRequest.processing_time_paid = false;
        queryClient.setQueryData([`v1/requests/${request.id}`], updatedRequest);
      },
      onError: (error) => {
        console.error('Failed to update processing time:', error);
        setProcessingTime(request.processing_time);
      },
    },
  );

  const handleProcessingTime = (option: any) => {
    setProcessingTime(option.label);

    request.processing_time = option.label;
    request.processing_time_paid = false;

    queryClient.setQueryData([`v1/requests/${request.id}`], request);

    updateProcessingTimeMutation.mutate(option.label);
  };

  // is active from friday at noon local time to sunday at 11:59pm local time
  // const isWeekend = () => {
  //   const date = new Date();
  //   const day = date.getDay();
  //   const hour = date.getHours();
  //   return day === 0 || day === 6 || (day === 5 && hour >= 11);
  // };

  // const isWeekendActive = request.processing_time == 'Weekend Expedited' || isWeekend();
  const isWeekendActive = false;

  return (
    <>
      <Card>
        <CardHeader className="p-3 md:p-4">
          <CardTitle>Processing Times</CardTitle>
        </CardHeader>
        <CardContent className="p-0 ">
          <div className="w-full divide-y divide-gray-200">
            <div
              className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100"
              onClick={() => handleProcessingTime(processingOptions[0])}
            >
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Clock size={18} />
                  <div className="text-sm font-medium text-gray-600">
                    {processingOptions[0].label} -{' '}
                    {USDollar.format(isMember ? processingOptions[0].membership_price : processingOptions[0].price)}
                  </div>
                </div>
                <div className="pr-20 text-xs font-normal text-gray-500">{processingOptions[0].description}</div>
              </div>
              <Switch checked={processingTime == processingOptions[0].label} />
            </div>
            <div
              className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100"
              onClick={() => handleProcessingTime(processingOptions[1])}
            >
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <FireIcon className="w-4 h-4" />
                  <div className="text-sm font-medium text-gray-600">
                    {processingOptions[1].label} -{' '}
                    {USDollar.format(isMember ? processingOptions[1].membership_price : processingOptions[1].price)}
                  </div>
                </div>
                <div className="pr-20 text-xs font-normal text-gray-500">{processingOptions[1].description}</div>
              </div>
              <Switch checked={processingTime == processingOptions[1].label} />
            </div>
            {isWeekendActive && (
              <div
                className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100"
                onClick={() => handleProcessingTime(processingOptions[2])}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <Clock size={18} />
                    <div className="text-sm font-medium text-gray-600">
                      {processingOptions[2].label} -{' '}
                      {USDollar.format(isMember ? processingOptions[2].membership_price : processingOptions[2].price)}
                    </div>
                  </div>
                  <div className="pr-20 text-xs font-normal text-gray-500">{processingOptions[2].description}</div>
                </div>
                <Switch checked={processingTime == processingOptions[2].label} />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ProcessingTimes;
