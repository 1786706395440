import { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ArrowUp, Plus, Search, Tag } from 'lucide-react';
import posthog from 'posthog-js';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsCapsulePill } from 'react-icons/bs';
import { FaUserDoctor } from 'react-icons/fa6';
import { GoNote } from 'react-icons/go';
import DrugSelector from '@/components/Medications/DrugSelector';
import Dosage from '@/components/Medications/Views/Dosage';
import Preference from '@/components/Medications/Views/Preference';
import Variant from '@/components/Medications/Views/Variant';
import Modal from '@/components/Modals/Modal';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea.tsx';
import useDrugRecords from '@/hooks/useDrugRecords';
import { IDrugBase, IMedication, IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import { USDollar } from '@/utils/helpers';

interface MedicationModalProps {
  request?: IRequest;
  medication?: IMedication;
  medications: IMedication[];
  defaultPath?: string;
}

const MedicationModal = NiceModal.create(({ request, medication, defaultPath, medications }: MedicationModalProps) => {
  const modal = useModal('medication-modal');
  const { data: drugRecords = [] } = useDrugRecords();

  const [search, setSearch] = useState('');
  const [path, setPath] = useState(medication ? 'newMedication' : defaultPath ? defaultPath : 'choosePath');
  const [selectedDrug, setSelectedDrug] = useState<string>(medication?.medication || '');
  const [drug, setDrug] = useState<IDrugBase>(
    drugRecords.find((r) => r.data.name === medication?.medication)?.data || ({} as IDrugBase),
  );
  const [label, setLabel] = useState(medication?.label || '');
  const [quantity, setQuantity] = useState(medication?.quantity || '');
  const [partialStockAllowed, setPartialStockAllowed] = useState(medication?.partial_stock_allowed || false);
  const [note, setNote] = useState(medication?.note || '');
  const [isSaving, setIsSaving] = useState(false);
  const [prescription, setPrescription] = useState({
    category: medication?.category || '',
    medication: medication?.medication || '',
    variant: medication?.variant || '',
    dosage: medication?.dosage || '',
    preference: medication?.preference || '',
  });

  useEffect(() => {
    if (medication && medication.id) {
      setPath('newMedication');
      setSelectedDrug(medication.medication);
      setDrug(drugRecords.find((r) => r.data.name === medication?.medication)?.data || ({} as IDrugBase));

      setLabel(medication.label);
      setQuantity(medication.quantity);
      setPartialStockAllowed(medication.partial_stock_allowed);
      setNote(medication.note);
      setPrescription({
        category: medication.category,
        medication: medication.medication,
        variant: medication.variant,
        dosage: medication.dosage,
        preference: medication.preference,
      });
    }
  }, [medication?.id, medications, drugRecords]);

  const handleDrugSelect = (drug: string) => {
    setSelectedDrug(drug);
    const foundDrug = drugRecords.find((r) => r.data.name === drug)?.data;
    setDrug(foundDrug || ({} as IDrugBase));
    setPrescription({
      category: foundDrug?.category || '',
      medication: drug,
      variant: '',
      dosage: '',
      preference: '',
    });
    update('category', drugRecords.find((r) => r.data.name === drug)?.data?.category || '');
    update('medication', drug);
  };

  const save = async () => {
    setIsSaving(true);
    posthog.capture('medication_added');

    const dosageForm = drug?.variants.find((variant: any) => variant.name === prescription.variant)?.dosageForm;

    const payload = {
      category: prescription.category,
      medication: prescription.medication,
      variant: prescription.variant,
      dosage: prescription.dosage,
      dosage_form: dosageForm,
      preference: prescription.preference,
      quantity,
      partial_stock_allowed: partialStockAllowed,
      note,
      drug,
      label,
      request_id: request?.id,
    };

    if (medication?.id) {
      axiosClient.put(`/v1/medications/${medication.id}`, payload).then(() => {
        window.location.reload();
      });
    } else {
      axiosClient.post(`/v1/medications`, payload).then(() => {
        window.location.reload();
      });
    }
  };

  const update = (key: string, value: string) => {
    const objectChanges = [
      {
        key: 'category',
        set: {
          category: value,
          medication: '',
          customMedication: '',
          variant: '',
          dosage: '',
          customDosage: '',
          preference: '',
        },
      },
      {
        key: 'medication',
        set: {
          medication: value,
          customMedication: '',
          variant: '',
          dosage: '',
          customDosage: '',
          preference: '',
        },
      },
      {
        key: 'customMedication',
        set: {
          medication: '',
          customMedication: value,
          variant: '',
          dosage: '',
          customDosage: '',
          preference: '',
        },
      },
      {
        key: 'variant',
        set: {
          variant: value,
          dosage: '',
          customDosage: '',
          preference: '',
        },
      },
      {
        key: 'dosage',
        set: { dosage: value },
      },
      {
        key: 'preference',
        set: { preference: value },
      },
    ];

    setPrescription({
      ...prescription,
      ...objectChanges.find((obj) => obj.key === key)?.set,
    });
  };

  const canViewVariant = prescription.medication !== '';
  const canViewDosage = canViewVariant && prescription.variant;
  const canViewPreference = canViewDosage && prescription.dosage;
  const canViewTheRest = canViewPreference && prescription.preference;

  const nonselectedMedications = medications?.filter(
    (medication: any) => !request?.medications.find((m: any) => m.id === medication.id),
  ) as IMedication[];

  const overridePath = !medication?.id
    ? nonselectedMedications.length > 0
      ? 'choosePath'
      : 'newMedication'
    : 'newMedication';
  const title = !medication?.id
    ? overridePath === 'newMedication'
      ? 'Medication'
      : 'Choose a Medication'
    : 'Update Prescription';
  const description = !medication?.id
    ? overridePath === 'newMedication'
      ? 'Choose a medication from the list'
      : 'Select from your previous medications or choose to create a new one'
    : 'Update your prescription information.';

  const addMedicationToRequest = (medication: any) => {
    axiosClient.put(`/v1/medication_request/${medication.id}/${request?.id}`).then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
      setIsSaving(false);
    });
  };

  const canSave = !(quantity && prescription.dosage && prescription.variant);

  return (
    <Modal modal={modal} title={title} description={description} wide>
      {overridePath === 'newMedication' || path === 'newMedication' ? (
        <>
          {selectedDrug ? (
            <>
              <div className="flex flex-col gap-4 p-1 ">
                <div className="flex flex-row items-center justify-between gap-4 p-2 border border-gray-300 rounded-lg">
                  <div>
                    <div className="flex items-center gap-2">
                      <div className="text-lg font-semibold">{selectedDrug}</div>
                    </div>
                    <div className="text-sm text-gray-500">
                      {drugRecords.find((r) => r.data.name === selectedDrug)?.data.generic}
                    </div>
                  </div>
                  <div className="cursor-pointer">
                    <Button variant="outline" onClick={() => setSelectedDrug('')}>
                      Change
                    </Button>
                  </div>
                </div>

                <div className="">
                  <Variant prescription={prescription} update={update} drug={drug} />
                </div>
                {canViewDosage ? (
                  <>
                    <div className="">
                      <Dosage prescription={prescription} update={update} drug={drug} />
                    </div>
                    {canViewPreference ? (
                      <div className="">
                        <Preference prescription={prescription} update={update} drug={drug} />
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col gap-2 ">
                          <Label className="w-20 text-sm font-medium leading-tight text-neutral-800">Preference</Label>
                          <div className="flex flex-row gap-2 text-xs text-purple-700 ">
                            Select a dosage above to continue
                            <ArrowUp className="w-4 h-4" />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-2 ">
                      <Label className="w-20 text-sm font-medium leading-tight text-neutral-800">Dosage</Label>
                      <div className="flex flex-row gap-2 text-xs text-purple-700 ">
                        Select which type of {selectedDrug} above to continue
                        <ArrowUp className="w-4 h-4" />
                      </div>
                    </div>
                  </>
                )}

                {canViewTheRest && (
                  <>
                    <div className="">
                      <Label htmlFor="label">Label this medication</Label>
                      <Input
                        type="text"
                        id="label"
                        autoComplete="off"
                        placeholder="To help organize your medications add a label, e.g. Ben's Medication"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <Label htmlFor="quantity">
                        How many is it written for?
                        <span className="ml-1 text-red-500">*</span>
                      </Label>
                      <Input
                        type="number"
                        id="quantity"
                        autoComplete="off"
                        placeholder="How many?"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    <div className="flex gap-4 my-6 items-top">
                      <Switch
                        id="partial_stock_allowed"
                        onCheckedChange={() => setPartialStockAllowed(!partialStockAllowed)}
                        checked={partialStockAllowed ? true : false}
                      />

                      <div className="grid gap-1.5 leading-none">
                        <label
                          htmlFor="partial_stock_allowed"
                          className="-mt-1 text-sm font-medium leading-1 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Are you willing to accept partial quantity if stock is limited?
                          <div className="mt-1 text-xs text-muted-foreground">
                            Needle will try to find the full quantity, but if it is not available, we will try to find
                            the closest quantity to what you need.
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <Label htmlFor="notes" className="flex items-center gap-2">
                        <GoNote size={'20'} className="text-gray-500" />
                        Notes
                      </Label>
                      <Textarea
                        id="notes"
                        autoComplete="off"
                        placeholder="Add any additional information that will be useful for Needle to know."
                        className="h-28"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center justify-between gap-8 ">
                      <Button className="w-full sm:w-auto" disabled={canSave || isSaving} onClick={save}>
                        {isSaving ? (
                          <>
                            <AiOutlineLoading className="w-4 h-4 mr-2 animate-spin" /> Saving Medication...
                          </>
                        ) : (
                          <>
                            <BsCapsulePill className="w-4 h-4 mr-2" />
                            {medication?.id ? 'Update Medication' : 'Add Medication'}
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <DrugSelector handleDrugSelect={handleDrugSelect} />
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col-reverse items-center justify-between gap-8 mb-4 md:flex-row">
            <div className="w-full ">
              <div className="relative">
                <Search className="absolute w-4 h-4 transform -translate-y-1/2 left-2 top-1/2 text-muted-foreground" />
                <Input
                  className="pl-8 "
                  placeholder="Search medications..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <Button onClick={() => setPath('newMedication')} className="w-full md:w-auto">
              <Plus />
              Create New Prescription
            </Button>
          </div>

          <ScrollArea className="h-[300px] rounded-md border border-gray-300 ">
            {nonselectedMedications
              .filter(
                (med) =>
                  med.medication.toLowerCase().includes(search.toLowerCase()) ||
                  med.dosage.toLowerCase().includes(search.toLowerCase()) ||
                  med.variant.toLowerCase().includes(search.toLowerCase()) ||
                  med.label?.toLowerCase().includes(search.toLowerCase()) ||
                  med.short_name.toLowerCase().includes(search.toLowerCase()),
              )
              .map((med) => (
                <div
                  key={med.id}
                  className="flex items-center justify-between p-3 border-b cursor-pointer hover:bg-accent "
                  onClick={() => addMedicationToRequest(med)}
                >
                  <div>
                    <div className="flex items-center gap-2 ">
                      <div className="text-lg ">{med.medication}</div>
                      {med.preference == 'Generic' && (
                        <div className="text-sm font-medium text-gray-500">(Generic preferred)</div>
                      )}
                      {med.preference == 'Brand' && (
                        <div className="text-sm font-medium text-gray-500">(Brand name preferred)</div>
                      )}
                    </div>
                    <p className="text-sm text-muted-foreground">
                      {med.dosage} {med.dosage_form} | {med.variant} | Qty {med.quantity}
                    </p>
                    <div className="flex gap-4 mt-1">
                      {med.label && (
                        <Badge variant="outline" className="">
                          <Tag className="w-3 h-3 mr-1" />
                          {med.label}
                        </Badge>
                      )}

                      {med.account_id && (
                        <div className="flex flex-col gap-2 md:flex-row md:items-center">
                          <Badge className="gap-2">
                            <FaUserDoctor className="w-2.5 h-2.5" />
                            Provider: {med.account?.owner.name} | {med.account?.name}
                          </Badge>
                          <Badge variant={'outline'} className="gap-1 text-gray-600 border-gray-300">
                            {med.account?.provider_type == 'covered' ? (
                              'Costs Covered by Provider'
                            ) : (
                              <>{USDollar.format(parseFloat(String(med.account?.provider_pharmacy_cost)))} Per Call</>
                            )}
                          </Badge>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </ScrollArea>
        </>
      )}
    </Modal>
  );
});

export default MedicationModal;
