import { useLocation } from 'react-router';
import { IUser } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useMe() {
  const location = useLocation();
  const { pathname } = location;

  const excludedPaths = ['/login', '/mfa', '/register'];

  const query = getUrl(`v1/users/me`, {
    enabled: !excludedPaths.some((excludedPath) => pathname.includes(excludedPath)),
  });

  return {
    ...query,
    data: query.data as IUser,
  };
}

export default useMe;
