import { BsStarFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { IRequest } from '@/types';
import { Button } from './ui/button';

interface Props {
  request: IRequest;
}

const TrustPilot = ({ request }: Props) => {
  const anyInStock = request.tasks?.some((task) => task.stock_status === 'in_stock') || false;

  if (!anyInStock) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl font-bold">Do you want leave a review?</CardTitle>
        <CardDescription>
          We value your feedback and would love to hear about your experience with Needle. Please take a moment to leave
          a review on TrustPilot. Your feedback helps us improve our service and helps others find us.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button variant={'outline'} asChild className="w-full mt-4 group">
          <Link to="https://www.trustpilot.com/review/findneedle.co" target="_blank" rel="noopener noreferrer">
            <BsStarFill className="w-4 h-4 mr-2 group-hover:text-yellow-500 text-primary" />
            Leave a Review
          </Link>
        </Button>
      </CardContent>
    </Card>
  );
};

export default TrustPilot;
